import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Container from "../components/container"
import TextSection from "../components/textSection"
import Slider from "../components/swiper"
import DescriptionSection from "../components/descriptionSection"
import IconSection from "../components/iconSection"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

import "./index.css"

const IndexPage = ( {data, location} ) => {

  // map catalogue link to catalogue section
  const catalogueDesc = data.page.frontmatter.catalogue_section;
  catalogueDesc.button_url = data.catalogue.frontmatter.file.publicURL;

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <section id="home-landing" className="page-section">
        <AnimationWrapper>
          <Slider data={data.page.frontmatter.slides} />
        </AnimationWrapper>
      </section>

      <Container>
        <TextSection className="oferta-description description-medium" data={data.page.frontmatter.text_section_main} />
      </Container>

      <section id="catalogue-section" className="page-section">
        <DescriptionSection location="catalogue" data={catalogueDesc} />
      </section>

      <section id="homepage-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icon_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="about-desc" className="page-section">
        {data.page.frontmatter.description_sections.map((description_section, key) => (
            <DescriptionSection key={key} location="o-marce" data={description_section} />
        ))}
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "index" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slides{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_url
          button_style
          text
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        catalogue_section{
          title
          title_visible
          subtitle
          align
          text
          button_text
          button_url
          button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          object_fit
        }
        icon_section{
          type
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        description_sections{
          title
          title_visible
          subtitle
          align
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          object_fit
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    catalogue: mdx(
      internal: {contentFilePath: {regex: "/(downloads)/"}},
      frontmatter: {category: {eq: "katalogi"}}
    ) {
      frontmatter {
        name
        file {
          publicURL
        }
      }
      id
    }
  }
`

export default IndexPage
